import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import * as CommonVariable from '../../Utility/CommonVariables';

export const GetAllBucketReasonList = async (id) => {
   
    if(id==undefined || id<=0)
    {
        var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + 'Bucket/GetBucketReasonsList', CommonVariable.API_HEADER)).then(response => response);
        return result;
    }
    else
    {
        var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `Bucket/GetBucketReasonsList?Id=${id}`, CommonVariable.API_HEADER)).then(response => response);
        return result;
    }
}

export const GetAllBucketMasterList = async () => {
    
        var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + 'Bucket/GetBucketMasterList', CommonVariable.API_HEADER)).then(response => response);
        return result;
}

export const SaveReasonDetails = async (model) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + 'Bucket/AddBucketReasons', JSON.stringify(model), CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const UpdateReasonDetails = async (model) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + 'Bucket/UpdateBucketReasons',JSON.stringify( model), CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const ActivateDeactiveReason = async (Id,IsActive) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + `Bucket/ActivateDeactiveReason?Id=${Id}&IsActive=${IsActive}`,CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const SearchReason = async (Search_Text) => {
    
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `Bucket/SearchBucketReasonsList?searchval=${Search_Text}`, CommonVariable.API_HEADER)).then(response => response);
    return result;
}
