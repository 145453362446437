import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Col, Form, Row, FormGroup, Label, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ContainerComponent from '../Container/ContainerComponent'
import { FaInfo, FaPlus } from 'react-icons/fa';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from "react-router-dom";
import * as CommonVariables from '../../Utility/CommonVariables';
import * as UserService from '../../Services/User/UserService';
import { useHistory } from 'react-router-dom'
import ReactJson from 'react-json-view';
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';
import useLoaderState from '../../Utility/useLoaderState'

function UserDetailComponent(props) {
    const { register, setValue, handleSubmit, reset, clearErrors, getValues, userData, formState: { errors } } = useForm({
        mode: "onBlur" // "onChange"
    });

    const roleData = [{ Key: "Admin", Value: "Admin" }, { Key: "User", Value: "User" }]
    const { User_Id } = useParams();
    const [error, setError] = useState("");
    const [IsLoading] = useLoaderState();
    const history = useHistory();

    useEffect(() => {
        if (User_Id > 0) {
            GetUserById();
        }
    }, []);

    const BackToList = (e) => {
        history.push('/user')
    }

    const GetUserById = () => {
        setError('');
        UserService.GetUserDetailsById(User_Id).then(response => {
            if (response.data.IsSuccess) {
                reset(response.data.Data);
                setValue('Confirm_Password', response.data.Data.Password)
            }
            else {
                toast.error(response.data.errorMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const SaveUser = (userData) => {
        setError('');
        UserService.SaveUserDetails(userData).then(data => {
            if (data.data.IsSuccess) {
                toast.success("User " + data.data.ResponseMessage);
                BackToList();
            } else {
                setError(data.data.ResponseMessage);
                toast.error(data.data.ResponseMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const UpdateUser = (userData) => {
        setError('');
        UserService.UpdateUserDetails(userData).then(data => {
            if (data.data.IsSuccess) {
                toast.success("User " + data.data.ResponseMessage);
                BackToList();
            } else {
                setError(data.data.ResponseMessage);
                toast.error(data.data.ResponseMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const allowonlynumber = (e) => {
        var a = [];
        var k = e.which;

        for (var i = 48; i < 58; i++)
            a.push(i);

        if (!(a.indexOf(k) >= 0))
            e.preventDefault();
    };


    return (

        <ContainerComponent Title='User Details' ShowAction="false">
            <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <FormGroup className="float-right">

            </FormGroup>
            <Form autoComplete="off">
                {error ? (<Alert color="danger">
                    {error}
                </Alert>) : <></>}
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />

                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label className="requiredfeild" > First Name</Label>
                            <input type="text" placeholder="First Name" className="form-control" {...register("First_Name", { required: true })} ></input>
                            {errors.First_Name && <p style={{ color: 'red' }}>First Name is Required</p>}
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="requiredfeild" > Last Name</Label>
                            <input type="text" placeholder="Last Name" className="form-control" {...register("Last_Name", { required: true })} ></input>
                            {errors.Last_Name && <p style={{ color: 'red' }}>Last Name is Required</p>}
                        </FormGroup>
                    </Col>

                </Row>

                <Row>
                    <Col md="6">
                        <FormGroup >
                            <Label className="requiredfeild">Email Id</Label>
                            <input type="text" className="form-control" placeholder="Email Id" {...register("Email_Id", {
                                required: "Email Id is Required",
                                pattern: {
                                    value: /^\w+([\.-]?\w+)@hicare.in$/i,
                                    message: "Email Id should end with @hicare.in"

                                }
                            })} />
                            {errors.Email_Id && <p style={{ color: 'red' }}>{errors.Email_Id.message}</p>}
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup >
                            <Label className="requiredfeild">Mobile No</Label>
                            <input type="text" className="form-control" onKeyPress={(e) => allowonlynumber(e)} placeholder="Mobile No" {...register("Mobile_No", {
                                required: "Mobile No is Required",
                                pattern: {
                                    value: /^[1-9][0-9]{9}$/i,
                                    message: "Invalid Mobile No."
                                },
                                maxLength: "10"
                            })} />
                            {errors.Mobile_No && errors.Mobile_No.type === "maxLength" && <p style={{ color: 'red' }}>{"Mobile No should not be greater or less than 10"}</p>}
                            {errors.Mobile_No && <p style={{ color: 'red' }}>{errors.Mobile_No.message}</p>}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label className="requiredfeild">Password</Label>
                            <input type="text" className="form-control" placeholder="Password"
                                {...register("Password", { required: "Password is Required" })}
                            />
                            {errors.Password && <p style={{ color: 'red' }}>{errors.Password.message}</p>}
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="requiredfeild" >Confirm Password</Label>
                            <input type="password" className="form-control" placeholder="Confirm Password"
                                {...register("Confirm_Password", {
                                    required: "Confirm Password is Required",
                                    validate: {
                                        matchPreviousPassword: (value) => {
                                            const { Password } = getValues();
                                            return Password === value || "Password should match";
                                        }
                                    }
                                })}
                            />
                            {errors.Confirm_Password && <p style={{ color: 'red' }}>{errors.Confirm_Password.message}</p>}
                        </FormGroup>
                    </Col>
                </Row>
                {
                    User_Id > 0 ? <Row>
                        {/* <Col md="6">
                        <FormGroup>
                            <Label className="requiredfeild">User Role</Label>
                            <select name="select" className="form-control" {...register("Role_Id", { required: "User Role is Required" })}>
                                <option value="">Select</option>
                                {
                                    roleData.map((r) => {
                                        return <option key={r.Key} value={r.Value}>{r.Value}</option>
                                    })
                                }
                            </select>
                            {errors.Role_Id && <p style={{ color: 'red' }}>{errors.Role_Id.message}</p>}
                        </FormGroup>

                    </Col> */}



                        <FormGroup check>
                            <Label className="requiredfeild" > Is Active</Label> &nbsp;&nbsp;
                            <input type="checkbox"  {...register("Is_Active")} />
                        </FormGroup>



                    </Row> : ""}
                {User_Id > 0 ?
                    <button type="submit" className="btn btn-primary" disabled={IsLoading} onClick={handleSubmit(UpdateUser)}>Update</button> :
                    <button type="submit" className="btn btn-primary" disabled={IsLoading} onClick={handleSubmit(SaveUser)}>Save</button>
                }
                {' '}<button type="button" className="btn btn-secondary" disabled={IsLoading} onClick={() => BackToList()}>Cancel</button>


            </Form>
        </ContainerComponent >
    );
}
export default UserDetailComponent