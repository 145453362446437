import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import "../src/style.scss";
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import axios from 'axios';
import { store,persistor } from './Store/store';


//Add Authorization Header in every HTTP request
axios.interceptors.request.use(function (config) {  
  const token = store.getState();  
  if (token.isAuthenticated)
    config.headers.Authorization = `Bearer ${token.userData.AccessToken}`;
  else
    config.headers.Authorization = null;

  return config;
});
//End

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
