import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import * as CommonVariable from '../../Utility/CommonVariables'

export const GetAllInventoryList = async (user_Id) => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `Inventory/GetInventoryList?userId=${user_Id}`, CommonVariable.API_HEADER)).then(response => response);
    return result;
}

export const GetInventoryHistoryByItemCode = async (itemCode) => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `Inventory/GetInventoryHistory?itemCode=${itemCode}`, CommonVariable.API_HEADER)).then(response => response);
    return result;
}

export const GetInventoryReportBySC = async (user_Id) => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `Inventory/GetInventoryListByServiceCenter?userId=${user_Id}`, CommonVariable.API_HEADER)).then(response => response);
    return result;
}

export const GetInventoryDumpDetails = async (user_Id) => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `Inventory/GetInventoryDumpDetail?userId=${user_Id}`, CommonVariable.API_HEADER)).then(response => response);
    return result;
}


export const GetBarcodeDetails = async (barcodeData) => {
    debugger;
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `Inventory/GenrateQRCode?qrcode=${barcodeData}`, CommonVariable.API_HEADER)).then(response => response);
    return result;
}

export const GetServiceCenterList = async (user_Id) => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `Inventory/GetServiceCenterList?userId=${user_Id}`, CommonVariable.API_HEADER)).then(response => response);
    return result;
}

export const StockTransfer = async (trasnferData) => {
    var result = await trackPromise(axios.post(CommonVariable.API_DOMAIN + `Inventory/StockTransfer`, JSON.stringify(trasnferData),CommonVariable.API_HEADER)).then(response => response);
    return result;  
}

export const GetDetailsByOrderNo = async (orderno) => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `Inventory/GetDetailsByOrderNo?orderno=${orderno}`, CommonVariable.API_HEADER)).then(response => response);
    return result;
}

export const AssignToCustomer = async (data) => {
    var result = await trackPromise(axios.post(CommonVariable.API_DOMAIN + `Inventory/AssignMissedInventoryToCustomer`, JSON.stringify(data),CommonVariable.API_HEADER)).then(response => response);
    return result;  
}

export const GetInventorySCTransferMapping = async (user_Id) => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `Inventory/GetInventorySCTransferMappingList?userId=${user_Id}`, CommonVariable.API_HEADER)).then(response => response);
    return result;
}

export const ActionInventory = async (data) => {
    debugger;
    var result = await trackPromise(axios.post(CommonVariable.API_DOMAIN + `Inventory/ActionInventory`, JSON.stringify(data),CommonVariable.API_HEADER)).then(response => response);
    return result;  
}
export const GenerateQRCodePDF = async (data) => {
    debugger;
    var result = await trackPromise(axios.post(CommonVariable.API_DOMAIN + `Inventory/GenerateQRCodePDF`,data,CommonVariable.API_HEADER)).then(response => response);
    return result;  
}

