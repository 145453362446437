import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, FormGroup, Table, Modal, ModalHeader, ModalBody, ModalFooter, Label, Row, Col, Alert } from 'reactstrap';
import { FaAlignLeft, FaUser, FaSearch, FaUpload, FaElementor, FaTelegramPlane, FaCity, FaCalendarAlt, FaSimCard, FaPrint } from 'react-icons/fa';
import { MdOutlineQrCode2, MdOutlineAssignment } from 'react-icons/md';
import * as InventoryService from '../../Services/Inventory/InventoryService';
import * as BucketReasonService from '../../Services/Bucket/BucketReasonService';
import ContainerComponent from '../Container/ContainerComponent';
import { useHistory } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast';
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';
import useLoaderState from '../../Utility/useLoaderState';
import { store } from '../../Store/store'
import ReactPagniate from "react-paginate";
import UrlImageDownloader from 'react-url-image-downloader';
import useDownloader from 'react-use-downloader';
import { FilePicker } from 'evergreen-ui'
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

//import ReactHTMLTableToExcel from 'react-html-table-to-excel';
function InventoryListComponent() {
    const { register, setValue, handleSubmit, watch, reset, clearErrors, getValues, reasonData, formState: { errors } } = useForm({
        mode: "onBlur" // "onChange"
    });
    const [InventorySearchList, setSearchInventoryList] = useState([]);
    const [InventoryList, setInventoryList] = useState([]);
    const [serviceCenterList, setServiceCenterList] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const [selectedItemCode, setItemCode] = useState(0);
    const [error, setError] = useState("");

    const [editMode, SetEditMode] = useState(false);
    const history = useHistory();
    const [isInfoOpen, setInfoToggle] = useState(false);
    const [isBarcodeOpen, setBarcodeToggle] = useState(false);
    const [isNewBarcodeOpen, setNewBarcodeToggle] = useState(false);
    const [isStockTransferOpen, setStockTransferToggle] = useState(false);
    const [isInventoryAssignToCustomerOpen, setInventoryAssignToCustomerToggle] = useState(false);
    const [IsLoading] = useLoaderState();
    const userInfo = store.getState();
    const [barcodeDetails, setBarcodeDetails] = useState("");
    const [BarcodeURL, setBarcodeURL] = useState("");
    const [NewBarcodeURL, setNewBarcodeURL] = useState("");
    const SearchText = watch("Search_Text", "");
    const NewBarcode_Text = watch("NewBarCode", "");

    const OrderNo_Text = watch("Order_No", "");
    const [OrderDetails, setOrderDetails] = useState([]);
    const [ShowOrderNoDetails, SetShowOrderNoDetails] = useState(false);

    const [SelectedFileDetails, setSelectedFileDetails] = useState("");

    const [currentPage, setCurrentPage] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const {
        size,
        elapsed,
        percentage,
        download,
        cancel,
        isInProgress,
    } = useDownloader();
    const PER_PAGE = 20;
    const offset = currentPage * PER_PAGE;
    const currentPageData = InventoryList
        .slice(offset, offset + PER_PAGE)
        .map(({ thumburl }) => <img src={thumburl} />);
    const pageCount = Math.ceil(InventoryList.filter((val) => val.Inventory_Code != null && val.Inventory_Code.toLowerCase().includes(SearchText.toLowerCase()) ||
        (val.Region_Name != null && val.Region_Name.toLowerCase().includes(SearchText.toLowerCase())) ||
        (val.Bucket_Name != null && val.Bucket_Name.toLowerCase().includes(SearchText.toLowerCase())) ||
        (val.Inventory_Age != null && val.Inventory_Age.toLowerCase().includes(SearchText.toLowerCase())) ||
        (val.Manufacturing_Date__Date != null && val.Manufacturing_Date__Date.toLowerCase().includes(SearchText.toLowerCase())) ||
        (val.Expiry_Date__Date != null && val.Expiry_Date__Date.toLowerCase().includes(SearchText.toLowerCase())) ||
        (val.Status != null && val.Status.toLowerCase().includes(SearchText.toLowerCase()))).length / PER_PAGE);
    useEffect(() => {
        GetAllInventoryList();
        setNewBarcodeURL("");

    }, [])
    const displayUsers = InventoryList.filter((val) => val.Inventory_Code != null && val.Inventory_Code.toLowerCase().includes(SearchText.toLowerCase()) ||
        (val.Region_Name != null && val.Region_Name.toLowerCase().includes(SearchText.toLowerCase())) ||
        (val.Bucket_Name != null && val.Bucket_Name.toLowerCase().includes(SearchText.toLowerCase())) ||
        (val.Inventory_Age != null && val.Inventory_Age.toLowerCase().includes(SearchText.toLowerCase())) ||
        (val.Manufacturing_Date__Date != null && val.Manufacturing_Date__Date.toLowerCase().includes(SearchText.toLowerCase())) ||
        (val.Expiry_Date__Date != null && val.Expiry_Date__Date.toLowerCase().includes(SearchText.toLowerCase())) ||
        (val.Status != null && val.Status.toLowerCase().includes(SearchText.toLowerCase())))
        .slice(offset, offset + PER_PAGE).map((Inventory, Index) => {
            return (
                <tr key={Index}>
                    <td>{Inventory.Id}</td>
                    <td>{Inventory.Inventory_Code}</td>
                    <td>{Inventory.Region_Name}</td>
                    <td>{Inventory.Bucket_Name}</td>
                    <td>{Inventory.Manufacturing_Date__Date}</td>
                    <td>{Inventory.Expiry_Date__Date}</td>
                    <td>{Inventory.Inventory_Age}</td>
                    <td>{Inventory.Used_Count}</td>
                    <td>{Inventory.Status}</td>
                    <th>
                        {
                            Inventory.Bucket_Name == 'Service Center' && Inventory.Status == 'Active' ?
                                <Tooltip title="Stock Transfer">
                                    <IconButton style={{ height: '10px', width: '45px' }}>
                                        <FaTelegramPlane style={{ color: 'green' }} onClick={() => { OpenStockTransferPopup(Inventory) }} />
                                    </IconButton></Tooltip>
                                : <></>}
                        <Tooltip title="View History">
                            <IconButton style={{ height: '10px', width: '45px' }}>
                                <FaAlignLeft style={{ color: 'green' }} onClick={() => { viewHistory(Inventory.Item_Code) }} /> &nbsp;
                            </IconButton></Tooltip>
                        {/* {
                            Inventory.Status != 'InTransit' ? */}
                        <Tooltip title="BarCode Details">
                            <IconButton style={{ height: '10px', width: '45px' }}>
                                <MdOutlineQrCode2 style={{ color: 'green' }} onClick={() => { ViewBarcodeDetails(Inventory) }} /> &nbsp;
                            </IconButton></Tooltip>
                        {/* : <></>} */}
                        {Inventory.Bucket_Name == 'Technician' && Inventory.Status != 'Faulty' ?
                            <Tooltip title="Assign To Customer">
                                <IconButton style={{ height: '10px', width: '45px' }}>
                                    <MdOutlineAssignment style={{ color: 'green' }} onClick={() => { AssignInventoryToCustomerPopUp(Inventory) }} />
                                </IconButton></Tooltip>
                            : ""}
                    </th>
                </tr>
            );

        }
        );

    const GetServiceCenterList = () => {
        setError('');
        debugger;
        InventoryService.GetServiceCenterList(userInfo.userData.Ref_Id).then(data => {
            if (data.data.IsSuccess) {
                console.log(data.data.Data)
                setServiceCenterList(data.data.Data);
            } else {
                setServiceCenterList([]);
                setError(data.data.ErrorMessage);
                toast.error(data.data.ErrorMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }
    const GetAllInventoryList = () => {
        setError('');
        InventoryService.GetAllInventoryList(userInfo.userData.Ref_Id).then(data => {
            console.log(data.data)
            if (data.data.IsSuccess) {
                console.log(data.data.Data)
                setInventoryList(data.data.Data);
            } else {
                setError(data.data.ErrorMessage);
                toast.error(data.data.ErrorMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const stockTransfer = () => {
        setError('');
        var serviceCenter = getValues('Service_Center_Id')
        if (serviceCenter != "" && serviceCenter != null && serviceCenter != undefined) {
            var data = {};
            data.Item_Code = selectedItemCode;
            data.Created_By = userInfo.userData.Ref_Id;
            data.Service_Center_Id = serviceCenter;
            console.log(data)
            InventoryService.StockTransfer(data).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data.Data)
                    setStockTransferToggle(!isStockTransferOpen);
                    toast.success('Stock Transfer Succesfully!')
                    GetAllInventoryList();
                } else {
                    setError(data.data.ErrorMessage);
                    toast.error(data.data.ErrorMessage);
                }
            }).catch(error => {
                if (error.message.includes("401")) {
                    toast.error("Your session is expired, Redirecting to Login page")
                    history.push('')
                }
                else {
                    setError(error.message);
                    toast.error(error.message);
                }
            });
        }
        else {
            toast.error("Select Service Center");
        }
    }

    const viewHistory = (itemCode) => {
        setError('');

        InventoryService.GetInventoryHistoryByItemCode(itemCode).then(data => {
            if (data.data.IsSuccess) {
                console.log(data.data.Data)
                setHistoryData(data.data.Data)
                toast.success('Inventory List');
                setInfoToggle(!isInfoOpen)
            } else {
                setError(data.data.ErrorMessage);
                toast.error(data.data.ErrorMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });

    }

    const ViewBarcodeDetails = (inventory) => {
        setError('');
        setBarcodeDetails(inventory);
        InventoryService.GetBarcodeDetails(inventory.Barcode_Data).then(data => {
            if (data.data.IsSuccess) {
                setBarcodeToggle(!isBarcodeOpen)
                console.log(data.data.Data)
                setBarcodeURL(data.data.Data)
            } else {
                setError(data.data.ErrorMessage);
                toast.error(data.data.ErrorMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });

    }
    const AssignInventoryToCustomerPopUp = (inventory) => {
        setError('');
        console.log(inventory)
        setBarcodeDetails(inventory);
        setInventoryAssignToCustomerToggle(!isInventoryAssignToCustomerOpen)
        InventoryService.GetBarcodeDetails(inventory.Barcode_Data).then(data => {
            if (data.data.IsSuccess) {
                console.log(data.data.Data)
            } else {
                setError(data.data.ErrorMessage);
                toast.error(data.data.ErrorMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const CreateBarcodeDetails = () => {
        debugger;
        setError('');
        if (NewBarcode_Text == undefined || NewBarcode_Text == '') {
            //setError("Barcode Text is empty.");
            toast.error("Barcode Text is empty.");
            return;
        }
        InventoryService.GetBarcodeDetails(NewBarcode_Text).then(data => {
            if (data.data.IsSuccess) {
                debugger
                console.log(data.data.Data)
                console.log(NewBarcodeURL);
                setNewBarcodeURL(data.data.Data)
                console.log(NewBarcodeURL);
                //setNewBarcodeToggle(!isNewBarcodeOpen)
            } else {
                setError(data.data.ErrorMessage);
                toast.error(data.data.ErrorMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const toggleBarcodePopup = () => {
        setBarcodeToggle(!isBarcodeOpen)
    };
    const toggleInventoryAssignPopup = () => {
        setValue("Order_No", "")
        SetShowOrderNoDetails(false);
        setInventoryAssignToCustomerToggle(!isInventoryAssignToCustomerOpen)
    };

    const toggleNewBarcodePopup = () => {
        debugger;
        setNewBarcodeToggle(!isNewBarcodeOpen)
    };

    const toggleInfoPopup = () => {
        setInfoToggle(!isInfoOpen)
    };
    function SearchReason() {
        debugger;
        //GetAllInventoryList();
        console.log("search working");
        if (SearchText != undefined && SearchText.length >= 3) {
            setError('');
            //GetAllInventoryList();
            setSearchInventoryList(InventoryList);

            const searchData = (

                InventorySearchList.filter((val) => val.Inventory_Code != null && val.Inventory_Code.toLowerCase().includes(SearchText.toLowerCase()) ||
                    (val.Region_Name != null && val.Region_Name.toLowerCase().includes(SearchText.toLowerCase())) ||
                    (val.Bucket_Name != null && val.Bucket_Name.toLowerCase().includes(SearchText.toLowerCase())) ||
                    (val.Inventory_Age != null && val.Inventory_Age.toLowerCase().includes(SearchText.toLowerCase())) ||
                    (val.Manufacturing_Date__Date != null && val.Manufacturing_Date__Date.toLowerCase().includes(SearchText.toLowerCase())) ||
                    (val.Expiry_Date__Date != null && val.Expiry_Date__Date.toLowerCase().includes(SearchText.toLowerCase()))
                ))
            if (searchData.length > 0) {
                setInventoryList(searchData);
            }
            else {
                //GetAllInventoryList();
            }
        }
    }
    function handlePageClick({ selected: selectedPage }) {
        debugger;
        setCurrentPage(selectedPage);
    }


    function printPage(divName) {
        debugger;
        var printContents = document.getElementById(divName).innerHTML;
        //const barcodeDaetails = "";
        var barcodeData = "";
        if (divName == "sectionToPrint") {
            barcodeData = NewBarcode_Text;
        }
        else {
            barcodeData = barcodeDetails.Barcode_Data;
        }

        //document.body.innerHTML = printContents;
        var a = window.open("_blank");
        a.document.write('<html><head>');
        a.document.write('</head><body>');
        a.document.write('<br />')
        a.document.write('<br />')
        a.document.write('<center> <h3>' + 'HiCare Services Pvt Ltd.' + '</center> </h3>');
        a.document.write('<center> <h3> Details:- ' + barcodeData + '</center></h3>');
        a.document.write('<center>' + printContents + '</center>');
        a.document.write('</body></html>');
        a.document.close();
        a.focus();
        a.print();
        a.close();
    }
    const toggleStockTransferPopup = () => {
        setValue("Service_Center_Id", "");
        setServiceCenterList([]);
        setStockTransferToggle(!isStockTransferOpen)
    };
    const OpenStockTransferPopup = (Data) => {
        GetServiceCenterList();
        setItemCode(Data.Item_Code);
        setStockTransferToggle(!isStockTransferOpen)
    };
    const SerachByOrderNo = () => {
        InventoryService.GetDetailsByOrderNo(OrderNo_Text).then(data => {
            if (data.data.IsSuccess) {
                console.log(data.data.Data)
                var Data = JSON.parse(data.data.Data);
                if (Data.data != null) {
                    SetShowOrderNoDetails(true)
                    setOrderDetails(Data.data[0])
                    SetShowOrderNoDetails(true)
                }
                else {
                    toast.error('No such record found for Order No :' + OrderNo_Text)
                    SetShowOrderNoDetails(false);
                    setOrderDetails([])
                }
            }
            else {
                setError(data.data.ErrorMessage);
                toast.error(data.data.ErrorMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });

    };
    const AssignToCustomer = () => {
        var data = {};
        data.ItemCode = barcodeDetails.Item_Code;
        data.Order_Number = OrderDetails.order_Number__c;
        data.Account_Name = OrderDetails.name;
        data.Bucket_Id = 3;
        data.User_Id = userInfo.userData.Ref_Id

        InventoryService.AssignToCustomer(data).then(data => {
            if (data.data.IsSuccess) {
                setInventoryAssignToCustomerToggle(!isInventoryAssignToCustomerOpen)
                toast.success('Inventory Assigned to Customer Successfully')
                GetAllInventoryList();
            } else {
                setError(data.data.ErrorMessage);
                toast.error(data.data.ErrorMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    };


    return (
        <ContainerComponent Title="Inventory" ButtonTitle="Barcode" Icon={<FaElementor />} AddNewAction={toggleNewBarcodePopup} ShowAction="true">
            {error ? (<Alert color="danger">
                {error}
            </Alert>) : <></>}
            <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <div className="row">
                <div className="col-8"></div>
                <div className="col-4">
                    <div className="input-group-text">
                        <input placeholder="Search..." {...register("Search_Text")} className="form-control" />
                        <div className="input-group-append">
                            <button className="btn btn-outline-success" type="button" >
                                {<FaSearch />}
                            </button>
                        </div>
                    </div></div>
            </div>
            <br></br>
            <Table id="inv">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Inventory Name</th>
                        <th>Service Center </th>
                        <th>Bucket Name</th>
                        <th>Manufacturing Date</th>
                        <th>Expiry Date</th>
                        <th>Inventory Aging</th>
                        <th>Used Count</th>
                        <th>Status</th>
                        <th style={{ width: "15%" }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {displayUsers}
                    {
                        InventoryList.filter((val) => val.Inventory_Code != null && val.Inventory_Code.toLowerCase().includes(SearchText.toLowerCase()) ||
                            (val.Region_Name != null && val.Region_Name.toLowerCase().includes(SearchText.toLowerCase())) ||
                            (val.Bucket_Name != null && val.Bucket_Name.toLowerCase().includes(SearchText.toLowerCase())) ||
                            (val.Inventory_Age != null && val.Inventory_Age.toLowerCase().includes(SearchText.toLowerCase())) ||
                            (val.Manufacturing_Date__Date != null && val.Manufacturing_Date__Date.toLowerCase().includes(SearchText.toLowerCase())) ||
                            (val.Expiry_Date__Date != null && val.Expiry_Date__Date.toLowerCase().includes(SearchText.toLowerCase())) ||
                            (val.Status != null && val.Status.toLowerCase().includes(SearchText.toLowerCase()))).length <= 0
                            ?
                            <tr>
                                <td colSpan="7">No Data Available</td>
                            </tr>
                            :
                            <></>
                    }
                </tbody>
            </Table>
            <ReactPagniate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                previousLinkClassName={"page-item"}
                nextLinkClassName={"page-item"}
                disabledClassName={"page-item disabled"}
                activeClassName={"page-item active"}
            />
            {/* <div>
                <ReactHTMLTableToExcel
                    className="btn btn-info"
                    table="inv"
                    filename="ReportExcel"
                    sheet="Sheet"
                    buttonText="Export excel" />
            </div> */}
            <Modal isOpen={isInfoOpen}>
                <ModalHeader charCode="x" toggle={() => toggleInfoPopup()}>
                    Inventory History
                </ModalHeader>
                <ModalBody>
                    <div >
                        {
                            historyData.map((data, Index) => {
                                return (

                                    <div className="card-body shadow">
                                        <Row >
                                            <Col md="5" className="col-box"><Label><FaSimCard /> Assigned Type :</Label></Col>
                                            <Col md="7" className="col-box">{data.Assigned_Type}</Col>
                                        </Row>
                                        <Row >
                                            <Col md="5" className="col-box"><Label><FaUser /> Assigned To :</Label></Col>
                                            <Col md="7" className="col-box">{data.Assigned_To}</Col>
                                        </Row>
                                        <Row >
                                            <Col md="5" className="col-box"><Label><FaCalendarAlt /> Assigned Date :</Label></Col>
                                            <Col md="7" className="col-box">{data.Assigned_Date_Formated} </Col>
                                        </Row>
                                        <Row >
                                            <Col md="5" className="col-box"><Label><FaCalendarAlt /> Status :</Label></Col>
                                            <Col md="7" className="col-box">{data.Status} </Col>
                                        </Row>
                                    </div>
                                );
                            })
                        }
                    </div>

                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-secondary" disabled={IsLoading} onClick={(e) => toggleInfoPopup()}>Close</button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={isBarcodeOpen}>
                <ModalHeader charCode="x" toggle={() => toggleBarcodePopup()}>
                    Barcode Details
                </ModalHeader>
                <ModalBody>

                    <Row >
                        <Col md="12" >
                            <Label><b> Item Code : </b>{barcodeDetails.Item_Code} </Label>  <br />
                            <Label><b> Make : </b>{barcodeDetails.Make} </Label>  <br />
                            <Label><b> Model :</b> {barcodeDetails.Model}</Label> <br />
                            <Label><b> Manufacturing Date : </b>
                                {barcodeDetails.Manufacturing_Date__Date}</Label> <br />
                            <Label><b>  Expiry Date : </b>   {barcodeDetails.Expiry_Date__Date}</Label>

                        </Col>
                        <Col md="5" >
                            <Row>
                                <img src={BarcodeURL} style={{ width: "100%", height: "100%", position: "relative" }} alt="HiCare" />
                            </Row>
                        </Col>
                        {/* <Col md="1" >
                            <br></br>
                            <a title="Print" style={{ marginLeft: "-39px" }}
                                href={BarcodeURL}
                                target={'_blank'}

                                // onClick={e => download(e)}>
                                onClick={() => download({ BarcodeURL }, "image.jpg")}>
                                {<FaPrint />}

                            </a></Col> */}

                    </Row>
                    <Row>
                        <Col md="2"></Col>  <Col md="8">
                            <div id="sectionToPrintBarcodeURL" hidden style={{ textAlign: "center" }}>

                                <img id="barcodeId" src={BarcodeURL} style={{ width: "50%", height: "70%", position: "relative" }} alt="HiCare" />

                            </div></Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {BarcodeURL.length > 0 ? <button className="btn btn-primary" disabled={IsLoading} onClick={(e) => printPage("sectionToPrintBarcodeURL")}>Print</button> : ""}
                    <button className="btn btn-secondary" disabled={IsLoading} onClick={(e) => toggleBarcodePopup()}>Close</button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={isInventoryAssignToCustomerOpen}>
                <ModalHeader charCode="x" toggle={() => toggleInventoryAssignPopup()}>
                    Inventory ({barcodeDetails.Item_Code})  Assign To Customer
                </ModalHeader>
                <ModalBody>
                    <Row >
                        <Col md="3" >
                            <Label><b>Order No </b></Label>
                        </Col>
                        <Col md="7" >
                            <input placeholder="Enter Order No" {...register("Order_No")} className="form-control" />
                        </Col>
                        <Col md="1" >
                            <button className="btn btn-outline-success" type="button" onClick={(e) => SerachByOrderNo()}>
                                <FaSearch />
                            </button>
                        </Col>
                    </Row>
                    {ShowOrderNoDetails ?
                        <><br />
                            <Row>
                                <Col md="1"></Col>
                                <Col md="10" style={{ border: "2px solid", borderRadius: "12px", padding: "10px" }}>
                                    <Label><b> Order No : </b>{OrderDetails.order_Number__c} </Label>  <br />
                                    <Label><b> Account Name : </b>{OrderDetails.name} </Label>  <br />
                                    <Label><b> Service Plan :</b> {OrderDetails.service_Plan_Name__c}</Label> <br />
                                </Col>
                            </Row><br />
                        </> : <></>}
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" disabled={IsLoading} onClick={(e) => AssignToCustomer()}>Assign</button>
                    <button className="btn btn-secondary" disabled={IsLoading} onClick={(e) => toggleInventoryAssignPopup()}>Close</button>
                </ModalFooter>
            </Modal>


            <Modal isOpen={isStockTransferOpen}>
                <ModalHeader charCode="x" toggle={() => toggleStockTransferPopup()}>
                    Stock Transfer
                </ModalHeader>
                <ModalBody>
                    <Row >
                        <Col md="5" className="col-box"><Label><FaElementor /> Inventory Name : </Label></Col>
                        <Col md="7" className="col-box">{selectedItemCode}</Col>
                    </Row>
                    <Row >
                        <Col md="5" className="col-box"><Label><FaCity /> Transfer To :</Label></Col>
                        <Col md="7" className="col-box">
                            <select className="form-control" {...register("Service_Center_Id", { required: "Mandatory field" })}>
                                <option value="">Select Service Center</option>
                                {
                                    serviceCenterList != null && serviceCenterList.map((data, index) => {
                                        return (
                                            <option value={data.Service_Center_Id} key={data.Service_Center_Code}>{data.Service_Center_Code}</option>
                                        )
                                    })
                                }
                            </select>
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <button type="submit" disabled={IsLoading} className="btn btn-success" onClick={stockTransfer}>Stock Transfer</button>
                    <button className="btn btn-secondary" disabled={IsLoading} onClick={(e) => toggleStockTransferPopup()}>Close</button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={isNewBarcodeOpen}>
                <ModalHeader charCode="x" toggle={() => toggleNewBarcodePopup()}>
                    New Barcode
                </ModalHeader>
                <ModalBody>
                    <Row>

                        <FormGroup>
                            <Row>
                                <Col md="4">
                                    <Label className="requiredfeild" > Barcode Text</Label>
                                </Col>
                                <Col md="8">
                                    <input type="text" placeholder="New Barcode" className="form-control" {...register("NewBarCode", { required: true })} ></input>
                                    {errors.NewBarCode && <p style={{ color: 'red' }}>Barcode Text is Required</p>}
                                </Col>

                                <Col md="8"></Col>

                                <Col md="4">
                                    <br />
                                    <button type="submit" className="btn btn-primary" disabled={IsLoading} onClick={CreateBarcodeDetails}>Create Barcode</button>

                                </Col>
                            </Row>
                        </FormGroup>
                    </Row>
                    {NewBarcodeURL.length > 0 ?
                        <Row>
                            <Col md="2"></Col>  <Col md="8">
                                <div style={{ textAlign: "center" }}>

                                    <img id="barcodeId" src={NewBarcodeURL} style={{ width: "100%", height: "100%", position: "relative" }} alt="HiCare" />
                                    {/* <UrlImageDownloader  imageUrl={NewBarcodeURL} /> */}

                                    {/* <button onClick={() => download(fileUrl, filename)}> */}

                                    {/* <a
                                        href={NewBarcodeURL}
                                        target={'_blank'}

                                        // onClick={e => download(e)}>
                                        onClick={() => download({ NewBarcodeURL }, "image.jpg")}>
                                        {<FaDownload />}

                                    </a> */}
                                </div>
                            </Col>
                            <Col md="2" >
                                <a title="Print" style={{ marginLeft: "-80px" }}
                                    href={NewBarcodeURL}
                                    target={'_blank'}

                                    // onClick={e => download(e)}>
                                    onClick={() => download({ NewBarcodeURL }, "image.jpg")}>
                                    {<FaPrint />}

                                </a></Col>
                            {/* <UrlImageDownloader imageUrl={NewBarcodeURL} /> */}
                        </Row> : ""}
                    <Row>
                        <Col md="2"></Col>  <Col md="8">
                            <div id="sectionToPrint" hidden style={{ textAlign: "center" }}>

                                <img id="barcodeId" src={NewBarcodeURL} style={{ width: "100%", height: "100%", position: "relative" }} alt="HiCare" />

                            </div></Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    {NewBarcodeURL.length > 0 ? <button className="btn btn-primary" disabled={IsLoading} onClick={(e) => printPage("sectionToPrint")}>Print</button> : ""}
                    <button className="btn btn-secondary" disabled={IsLoading} onClick={(e) => toggleNewBarcodePopup()}>Close</button>
                </ModalFooter>
            </Modal>

        </ContainerComponent>
    )
}


export default InventoryListComponent
