import React, { useState, useEffect } from 'react'
import { Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, Label, Row, Col, Alert } from 'reactstrap';
import { FaCalendarWeek, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import * as InventoryService from '../../Services/Inventory/InventoryService';
import ContainerComponent from '../Container/ContainerComponent';
import { useHistory } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast';
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';
import useLoaderState from '../../Utility/useLoaderState';
import { store } from '../../Store/store'
import * as CommonVariable from '../../Utility/CommonVariables'
import ReactPagniate from "react-paginate";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

function InventorySCMappingComponent() {

    const [InventoryList, setInventoryList] = useState([]);
    const [error, setError] = useState("");
    const history = useHistory();
    const [isInfoOpen, setInfoToggle] = useState(false);
    const [IsLoading] = useLoaderState();
    const userInfo = store.getState();

    const [currentPage, setCurrentPage] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const PER_PAGE = 20;
    const offset = currentPage * PER_PAGE;
    const currentPageData = InventoryList
        .slice(offset, offset + PER_PAGE)
        .map(({ thumburl }) => <img src={thumburl} />);
    const pageCount = Math.ceil(InventoryList.length / PER_PAGE);
    useEffect(() => {
        GetInventorySCTransferMapping();
    }, [])
    const displayUsers = InventoryList.slice(offset, offset + PER_PAGE).map((Inventory, Index) => {

        return (
            <tr key={Index}>
                <td>{Inventory.Inventory_Code}</td>
                <td>{Inventory.Source_ServiceCenter_Code}</td>
                <td>{Inventory.Destination_ServiceCenter_Code}</td>
                <td>{Inventory.Status}</td>
                <td>{Inventory.Created_On_Date}</td>
                <td>
                    {Inventory.Is_Owner ?
                        <Tooltip title="Revoke">
                            <IconButton style={{ height: '15px' }}><FaTimesCircle style={{ color: 'red' }} onClick={() => { ActionInventory(Inventory, "Revoke") }} /> </IconButton>
                        </Tooltip>
                        : ""}
                    {Inventory.Is_Acceptor ?
                        <>
                            <Tooltip title="Accept">
                                <IconButton style={{ height: '10px' }}>
                                    <FaCheckCircle style={{ color: 'green' }} onClick={() => { ActionInventory(Inventory, "Accept") }} /> </IconButton>
                            </Tooltip>
                            <Tooltip title="Reject">
                                <IconButton style={{ height: '10px' }}>
                                    <FaTimesCircle style={{ color: 'red' }} onClick={() => { ActionInventory(Inventory, "Reject") }} /></IconButton>
                            </Tooltip>
                        </> : ""}


                </td>


            </tr>
        );
        {
            InventoryList.length <= 0
                ?
                <tr>
                    <td colSpan="7">No Data Available</td>
                </tr>
                :
                <></>
        }
    }
    );
    function handlePageClick({ selected: selectedPage }) {
        debugger;
        setCurrentPage(selectedPage);
    }
    const GetInventorySCTransferMapping = () => {
        setError('');
        InventoryService.GetInventorySCTransferMapping(userInfo.userData.Ref_Id).then(data => {

            console.log(data)
            if (data.data.IsSuccess) {
                console.log(data.data.Data)
                setInventoryList(data.data.Data);
            } else {
                setInventoryList([]);
                setError(data.data.ErrorMessage);
                toast.error(data.data.ErrorMessage);
            }
        }).catch(error => {
            setInventoryList([]);
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const ActionInventory = (value, status) => {
        var action = window.confirm('Do you really want to ' + status + ' Inventory ?');
        if (action == true) {
            value.Status = status;
            value.User_Id = userInfo.userData.Ref_Id;
            InventoryService.ActionInventory(value).then(data => {
                if (data.data.IsSuccess) {
                    GetInventorySCTransferMapping();
                } else {
                    setError(data.data.ErrorMessage);
                    toast.error(data.data.ErrorMessage);
                }
            }).catch(error => {
                if (error.message.includes("401")) {
                    toast.error("Your session is expired, Redirecting to Login page")
                    history.push('')
                }
                else {
                    setError(error.message);
                    toast.error(error.message);
                }
            });
        }
    }

    return (
        <ContainerComponent Title="Inventory SC Transfer Mapping" Icon={<FaCalendarWeek />} ShowAction="false">
            {error ? (<Alert color="danger">
                {error}
            </Alert>) : <></>}
            <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            <Table>
                <thead>
                    <tr>
                        <th>Inventory Code</th>
                        <th>Source SC Code </th>
                        <th>Destination SC Code</th>
                        <th>Status</th>
                        <th>Created On</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {displayUsers}
                </tbody>
            </Table>

            <ReactPagniate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                previousLinkClassName={"page-item"}
                nextLinkClassName={"page-item"}
                disabledClassName={"page-item disabled"}
                activeClassName={"page-item active"}
            />
        </ContainerComponent>
    )
}


export default InventorySCMappingComponent