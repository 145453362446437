import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import * as CommonVariable from '../../Utility/CommonVariables';

export const GetBucketMasterListById = async (id) => {
   debugger;
    if(id==undefined || id<=0)
    {
        var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + 'Bucket/GetBucketMasterListById', CommonVariable.API_HEADER)).then(response => response);
        return result;
    }
    else
    {
        var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `Bucket/GetBucketMasterListById?Id=${id}`, CommonVariable.API_HEADER)).then(response => response);
        return result;
    }
}

export const GetAllBucketMasterList = async () => {
    
        var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + 'Bucket/GetBucketMasterList', CommonVariable.API_HEADER)).then(response => response);
        return result;
}

export const SaveBucketMasterDetails = async (model) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + 'Bucket/AddBucketMaster', JSON.stringify(model), CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const UpdateBucketMasterDetails = async (model) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + 'Bucket/UpdateBucketMaster',JSON.stringify( model), CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const ActivateDeactiveBucketMaster = async (Id,IsActive) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + `Bucket/ActivateDeactiveBucketMaster?Id=${Id}&IsActive=${IsActive}`,CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const SearchReason = async (Search_Text) => {
    
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `Bucket/SearchBucketMasterList?searchval=${Search_Text}`, CommonVariable.API_HEADER)).then(response => response);
    return result;
}