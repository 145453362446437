import axios from "axios";
import * as CommonVariables from "../../Utility/CommonVariables";

export const ValidateUser = async (user) => {

    var result = await axios.post(CommonVariables.API_DOMAIN + "Login/ValidateUser",user, CommonVariables.API_HEADER)
    .then(response => response);
    return result;
}

export const ValidateOTP = async (user) => {

    var result = await axios.post(CommonVariables.API_DOMAIN + "Login/ValidateOTP",user, CommonVariables.API_HEADER)
    .then(response => response);
    return result;
}