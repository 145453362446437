import React from 'react'
import LoginComponent from '../Components/Login/LoginComponent'

function LoginPage() {
    return (
        <section className="vh-100">
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col col-xl-10">
                        <div className="card" >
                            <div className="row g-0">
                                <div className="col-md-6 col-lg-5 d-none d-md-block">
                                    <img
                                        src="inventorylogin.png"
                                        alt="login form"
                                        className="img-fluid"
                                        style={{ width: "600px", height: "500px", position: "relative" }}
                                    />
                                </div>
                                <LoginComponent />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LoginPage
