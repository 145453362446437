import React, {  useState, useEffect } from 'react'
import { Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, Label, Row, Col, Alert } from 'reactstrap';
import { FaFileExcel,  FaWpforms, FaUserAlt, FaCalendarAlt, FaSimCard } from 'react-icons/fa';
import * as InventoryService from '../../Services/Inventory/InventoryService';
import ContainerComponent from '../Container/ContainerComponent';
import { useHistory } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast';
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';
import useLoaderState from '../../Utility/useLoaderState';
import { store } from '../../Store/store'
import * as CommonVariable from '../../Utility/CommonVariables'
import ReactPagniate from "react-paginate";

function InventoryDetailsBySC() {

    const [InventorySCList, setInventorySCList] = useState([]);
    const [error, setError] = useState("");
    const [editMode, SetEditMode] = useState(false);
    const history = useHistory();
    const [isInfoOpen, setInfoToggle] = useState(false);
    const [IsLoading] = useLoaderState();
    const userInfo = store.getState();



    const [currentPage, setCurrentPage] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const PER_PAGE = 20;
    const offset = currentPage * PER_PAGE;
    const currentPageData = InventorySCList
        .slice(offset, offset + PER_PAGE)
        .map(({ thumburl }) => <img src={thumburl} />);
    const pageCount = Math.ceil(InventorySCList.length / PER_PAGE);


    useEffect(() => {
        GetInventoryReportBySC();
    }, [])

    const displayUsers = InventorySCList.slice(offset, offset + PER_PAGE).map((Inventory, Index) => {

        return (
            <tr key={Index}>
                <td>{Index + 1}</td>
                <td>{Inventory.SC_Code}</td>
                <td>{Inventory.Total_Inventory}</td>
                <td>{Inventory.Active_Inventory}</td>
                <td>{Inventory.Free_Inventory}</td>
                <td>{Inventory.In_Use_Inventory}</td>
            </tr>
        );
        {
            InventorySCList.length <= 0
                ?
                <tr>
                    <td colSpan="7">No Data Available</td>
                </tr>
                :
                <></>
        }
    }
    );

    const GetInventoryReportBySC = () => {
        setError('');
        InventoryService.GetInventoryReportBySC(userInfo.userData.Ref_Id).then(data => {

            console.log(data.data.Data)
            if (data.data.IsSuccess) {
                console.log(data.data.Data)
                setInventorySCList(data.data.Data);
            } else {
                setError(data.data.ErrorMessage);
                toast.error(data.data.ErrorMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const GetInventoryBySCReport = () => {
        if (InventorySCList != null) {
            window.open(CommonVariable.API_DOMAIN + `Inventory/GetInventoryRptByServiceCenter?userId=${userInfo.userData.Ref_Id}`, "_blank");
        }
        else {
            toast.error('No Record Found')
        }
    }
    function handlePageClick({ selected: selectedPage }) {
        debugger;
        setCurrentPage(selectedPage);
    }
    return (
        <ContainerComponent Title="Inventory Summary Report" Icon={< FaWpforms />} ShowAction="false"
            DownloadBtnTitle="Download Report" BtnIcon={<FaFileExcel />} AddDownloadAction={GetInventoryBySCReport} ShowDownloadAction="true">
            {error ? (<Alert color="danger">
                {error}
            </Alert>) : <></>}
            <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Table>
                <thead>
                    <tr>
                        <th>Sr No</th>
                        <th>Service Center Code </th>
                        <th>Total Inventory</th>
                        <th>Active Inventory</th>
                        <th>Free Inventory</th>
                        <th>In Use Inventory</th>
                    </tr>
                </thead>
                <tbody>
                    {displayUsers}
                </tbody>
            </Table>
            <ReactPagniate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                previousLinkClassName={"page-item"}
                nextLinkClassName={"page-item"}
                disabledClassName={"page-item disabled"}
                activeClassName={"page-item active"}
            />
        </ContainerComponent>
    )
}


export default InventoryDetailsBySC
