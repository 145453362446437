import React, { useState, useEffect } from 'react'
import { Button, FormGroup, Table, Modal, ModalHeader, ModalBody, ModalFooter, Label, Row, Col, Alert } from 'reactstrap';
import { FaDownload, FaUpload, FaQrcode, FaHandPointRight } from 'react-icons/fa';
import * as InventoryService from '../../Services/Inventory/InventoryService';
import ContainerComponent from '../Container/ContainerComponent';
import { useHistory } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast';
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';
import useLoaderState from '../../Utility/useLoaderState';
import { store } from '../../Store/store'
import * as CommonVariable from '../../Utility/CommonVariables'
import ReactPagniate from "react-paginate";
import { ConsoleIcon, FilePicker } from 'evergreen-ui';
import { useForm } from 'react-hook-form';
import { Route, BrowserRouter as Router, Link, Switch } from 'react-router-dom';

function QRCodeGenerateComponent() {

    const { register, setValue, handleSubmit, watch, reset, clearErrors, getValues, reasonData, formState: { errors } } = useForm({
        mode: "onBlur" // "onChange"
    });

    const [SelectedFileDetails, setSelectedFileDetails] = useState("");
    const [error, setError] = useState("");
    const [QRCodeURL, setQRCodeURL] = useState("");
    // const [IsLoading] = useLoaderState();


    const GenerateQRCodePDF = (e) => {
        e.preventDefault();
        debugger;
        setError('');
        if (SelectedFileDetails != null && SelectedFileDetails != "") {
            setQRCodeURL("");
            var formdata = new FormData();
            formdata.append('Myfile', SelectedFileDetails[0])

            InventoryService.GenerateQRCodePDF(formdata).then(response => {
                debugger;
                if (response.data.IsSuccess) {
                    setSelectedFileDetails('');
                    toast.success("QRCode Generated Successfully");
                    setQRCodeURL(response.data.Data)
                    console.log(QRCodeURL);
                }
                else {
                    toast.success("Something Went Wrong,Recheck Uploaded File");
                }
            }).catch(error => {
                if (error.message.includes("401")) {
                    toast.error("Your session is expired, Redirecting to Login page")
                    history.push('')
                }
                else {
                    setError(error.message);
                    toast.error(error.message);
                }
            });
        }
        else {
            toast.error('Please choose Excel File First!')
        }
    }

    return (
        <ContainerComponent Title="Generate QRCode" Icon={<FaQrcode />} ShowAction="false"
            ShowDownloadAction="false">

            {error ? (<Alert color="danger">
                {error}
            </Alert>) : <></>}
            <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <br />
            <Row>
                <Col md="1"></Col>
                <Col md="3">
                    <FormGroup >
                        <FilePicker
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            width={200}
                            placeholder="Select File"
                            onChange={files => setSelectedFileDetails(files)}
                            name="SelectedFileDetails"
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <FormGroup >
                        <button className="btn btn-primary" onClick={(e) => GenerateQRCodePDF(e)} color="success"><FaUpload /> {' '} Upload</button>
                    </FormGroup>
                </Col>
                <Col md="3" >
                    <FormGroup >
                        <a style={{ float: "left" }} target="_blank" href="https://s3.ap-south-1.amazonaws.com/hicare-others/b052d273-919a-4556-8a95-fb89b22d7b81.xlsx" > <button className="btn btn-primary"><FaDownload /> Download Excel Format</button></a>
                    </FormGroup>
                </Col>
            </Row>
            {
                (QRCodeURL != "" & QRCodeURL != undefined) ?
                    < Row style={{ margin: "10px", paddingLeft: "450px" }}>
                        <a href={QRCodeURL} style={{ textDecoration: "none" }} target={'_blank'} ><FaHandPointRight /> {' '} Click Here QR Code URL to get pdf</a>
                    </Row>
                    : <></>
            }
        </ContainerComponent >
    )
}


export default QRCodeGenerateComponent