import React from 'react'
import { FaCalendarWeek, FaWpforms, FaElementor, FaBitbucket, FaHistory, FaBuffer, FaQrcode } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { store } from '../../Store/store'

function LeftMenuComponent() {

    const userInfo = store.getState();

    return (
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

            <Link to='/home'>
                <div className="sidebar-brand-icon rotate-n-15">
                    <i className="fas fa-laugh-wink"></i>
                </div>
                <img src="hicare-logo.png" style={{ height: "50px", width: "115px", marginTop: "10px", marginLeft: "10px" }} alt="HiCare" ></img>
            </Link>

            <div className="sidebar-heading">

            </div>
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                {/* <li className="nav-item">
                    <Link to='/user' className="nav-link collapsed"><FaUser /> {' '} User</Link>
                </li> */}
                <li className="nav-item">
                    <Link to='/inventory' className="nav-link collapsed"><FaElementor /> {' '} Inventory</Link>
                </li>
                <li className="nav-item">
                    <Link to='/inventoryscmapping' className="nav-link collapsed"><FaCalendarWeek /> {' '} Inventory SC Mapping</Link>
                </li>
                <li className="nav-item">
                    <Link to='/bucketMaster' className="nav-link collapsed"><FaBitbucket /> {' '} Bucket Master</Link>
                </li>
                <li className="nav-item">
                    <Link to='/bucketReason' className="nav-link collapsed"><FaHistory /> {' '} Bucket Reason Master</Link>
                </li>
                <li className="nav-item">
                    <Link to='/inventorydetailbysc' className="nav-link collapsed"><FaWpforms /> {' '} Inventory Summary Report</Link>
                </li>
                <li className="nav-item">
                    <Link to='/inventorydumpdetail' className="nav-link collapsed"><FaBuffer /> {' '} Inventory Detail Report</Link>
                </li>
                {userInfo.userData.First_Name == 'Rajesh Vijapurkar' ?
                    <li className="nav-item">
                        <Link to='/generateqrcode' className="nav-link collapsed"><FaQrcode /> {' '} Genrate QRCode</Link>
                    </li> : <></>
                }


            </ul>

        </ul>

    )
}

export default LeftMenuComponent
