import logo from './logo.svg';
import './App.css';
import { Route, BrowserRouter as Router, Link, Switch } from 'react-router-dom';
import _loginLayout from './Layouts/_loginLayout';
import _dashboardLayout from './Layouts/_dashboardLayout';
import DashboardPage from './Pages/DashboardPage';
import LoginPage from './Pages/LoginPage';
import UserPage from './Pages/UserListPage';
import UserDetail from './Pages/UserDetailPage';
import BucketPage from './Pages/BucketReasonListPage';
import BucketDetails from './Pages/BucketReasonDetailPage';
import BucketMasterPage from './Pages/BucketMasterListPage';
import BucketMasterDetails from './Pages/BucketMasterDetailPage';
import InventoryPage from './Pages/InventoryListPage';
import InventoryDetailsBySCPage from './Pages/InventoryDetailsBySCPage';
import InventoryDumpDetail from './Components/InventoryReports/InventoryDumpDetail';
import InventorySCMappingListPage from './Pages/InventorySCMappingListPage';
import QRCodeGenerateComponent from './Pages/QRCodeGenratorFilePage';


function App() {

  return (
    <Router>
      <Switch>
        <_loginLayout path="/" exact component={LoginPage}></_loginLayout>
        <_loginLayout path="/Login" exact component={LoginPage}></_loginLayout>
        <_dashboardLayout path="/home" exact component={DashboardPage}></_dashboardLayout>
        <_dashboardLayout path="/user" exact component={UserPage}></_dashboardLayout>
        <_dashboardLayout path="/user/add/:User_Id" component={UserDetail}></_dashboardLayout>
        <_dashboardLayout path="/user/edit/:User_Id" component={UserDetail}></_dashboardLayout>
        <_dashboardLayout path="/bucketReason" exact component={BucketPage}></_dashboardLayout>
        <_dashboardLayout path="/bucketReason/add/:Reason_Id" component={BucketDetails}></_dashboardLayout>
        <_dashboardLayout path="/bucketReason/edit/:Reason_Id" component={BucketDetails}></_dashboardLayout>
        <_dashboardLayout path="/bucketMaster" exact component={BucketMasterPage}></_dashboardLayout>
        <_dashboardLayout path="/bucketMaster/add/:Master_Id" component={BucketMasterDetails}></_dashboardLayout>
        <_dashboardLayout path="/bucketMaster/edit/:Master_Id" component={BucketMasterDetails}></_dashboardLayout>
        <_dashboardLayout path="/inventory" exact component={InventoryPage}></_dashboardLayout>
        <_dashboardLayout path="/inventoryscmapping" exact component={InventorySCMappingListPage}></_dashboardLayout>
        <_dashboardLayout path="/inventorydetailbysc" exact component={InventoryDetailsBySCPage}></_dashboardLayout>
        <_dashboardLayout path="/inventorydumpdetail" exact component={InventoryDumpDetail}></_dashboardLayout>
        <_dashboardLayout path="/generateqrcode" exact component={QRCodeGenerateComponent}></_dashboardLayout>
      </Switch>
    </Router>

  );
}

export default App;
