import React, {  useState, useEffect } from 'react'
import { Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, Label, Row, Col, Alert } from 'reactstrap';
import { FaFileExcel, FaUser, FaBuffer } from 'react-icons/fa';
import * as InventoryService from '../../Services/Inventory/InventoryService';
import ContainerComponent from '../Container/ContainerComponent';
import { useHistory } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast';
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';
import useLoaderState from '../../Utility/useLoaderState';
import { store } from '../../Store/store'
import * as CommonVariable from '../../Utility/CommonVariables'
import ReactPagniate from "react-paginate";

function InventoryDumpDetail() {

    const [InventoryList, setInventoryList] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const [selectedInventoryId, setInventoryId] = useState(0);
    const [error, setError] = useState("");
    const [editMode, SetEditMode] = useState(false);
    const history = useHistory();
    const [isInfoOpen, setInfoToggle] = useState(false);
    const [IsLoading] = useLoaderState();
    const userInfo = store.getState();

    const [currentPage, setCurrentPage] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const PER_PAGE =20;
    const offset = currentPage * PER_PAGE;
    const currentPageData = InventoryList
        .slice(offset, offset + PER_PAGE)
        .map(({ thumburl }) => <img src={thumburl} />);
    const pageCount = Math.ceil(InventoryList.length / PER_PAGE);
    useEffect(() => {
        GetInventoryDumpDetails();
    }, [])
    const displayUsers = InventoryList.slice(offset, offset + PER_PAGE).map((Inventory, Index) => {

        return (
            <tr key={Index}>
            <td>{Inventory.Service_Center_Code}</td>
            <td>{Inventory.Inventory_Code}</td>
            <td>{Inventory.Manufacturing_Date_Formated}</td>
            <td>{Inventory.Expiry_Date_Formated}</td>
            <td>{Inventory.Assigned_To}</td>            
            <td>{Inventory.Bucket_Name}</td>
            <td>{Inventory.Used_Count}</td>
            <td>{Inventory.Status}</td>
        </tr>
        );
        {
            InventoryList.length <= 0
                ?
                <tr>
                    <td colSpan="7">No Data Available</td>
                </tr>
                :
                <></>
        }
    }
    );


    function handlePageClick({ selected: selectedPage }) {
        debugger;
        setCurrentPage(selectedPage);
    }
    const GetInventoryDumpDetails = () => {
        setError('');
        InventoryService.GetInventoryDumpDetails(userInfo.userData.Ref_Id).then(data => {

            console.log(data)
            if (data.data.IsSuccess) {
                console.log(data.data.Data)
                setInventoryList(data.data.Data);
            } else {
                setError(data.data.ErrorMessage);
                toast.error(data.data.ErrorMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }
    const GetInventoryDumpCReport = () => {
        if (InventoryList != null) {
            window.open(CommonVariable.API_DOMAIN + `Inventory/GetInventoryDumpDetailsRpt?userId=${userInfo.userData.Ref_Id}`, "_blank");
        }
        else {
            toast.error('No Record Found')
        }
    }
    return (
        <ContainerComponent Title="Inventory Detail Report" Icon={<FaBuffer />} ShowAction="false"
            DownloadBtnTitle="Download Report" BtnIcon={<FaFileExcel />} AddDownloadAction={GetInventoryDumpCReport} ShowDownloadAction="true">
            {error ? (<Alert color="danger">
                {error}
            </Alert>) : <></>}
            <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Table>
                <thead>
                    <tr>
                        <th>Service Center Code </th>
                        <th>Inventory Code</th>
                        <th>Manufacturing Date</th>
                        <th>Expiry Date</th>
                        <th>Assigned To</th>                        
                        <th>Bucket Name</th>
                        <th>Used Count</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {displayUsers}
                </tbody>
            </Table>
            {/* {
                        InventoryList.map((Inventory, Index) => {
                            return (
                                <tr key={Index}>
                                    <td>{Inventory.Service_Center_Code}</td>
                                    <td>{Inventory.Inventory_Code}</td>
                                    <td>{Inventory.Manufacturing_Date_Formated}</td>
                                    <td>{Inventory.Expiry_Date_Formated}</td>
                                    <td>{Inventory.Assigned_To}</td>
                                    <td>{Inventory.Assigned_Type}</td>
                                    <td>{Inventory.Bucket_Name}</td>
                                    <td>{Inventory.Used_Count}</td>
                                    <td>{Inventory.Status}</td>
                                </tr>
                            );
                        })
                    }
                    {
                        InventoryList.length <= 0
                            ?
                            <tr>
                                <td colSpan="7">No Data Available</td>
                            </tr>
                            :
                            <></>
                    } */}
            <ReactPagniate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                previousLinkClassName={"page-item"}
                nextLinkClassName={"page-item"}
                disabledClassName={"page-item disabled"}
                activeClassName={"page-item active"}
            />
        </ContainerComponent>
    )
}


export default InventoryDumpDetail