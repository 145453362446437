import React from 'react'

function DashboardComponent() {


    
    return (
        <div style={{backgroundColor:"white"}}>
            <img src={"inventorydashboard.jpg"} style={{ width: "95%" , height: "70%", left:"30px",position:"relative" }} alt="HiCare" />
        </div>
    )
}

export default DashboardComponent
