import React from 'react'
import { FaPlus, FaFileExcel } from 'react-icons/fa'
import { Col, Form, Row, FormGroup, Label, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardHeader, CardBody } from 'reactstrap'
function ContainerComponent(props) {

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card shadow mb-6">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-5">
                                    <h5 className="w-75 text-success">{props.Icon} {props.Title}</h5>
                                </div>

                                {
                                    props.ShowDownloadAction == "true" && props.ShowAction == "false" ?
                                        (<div className="col-md-7" style={{ paddingRight: "2px" }}>
                                            <button className="btn btn-primary float-right" style={{ whiteSpace: 'nowrap' }} onClick={() => props.AddDownloadAction()} color="success">{props.BtnIcon} {props.DownloadBtnTitle}</button>
                                        </div>) :
                                        (<></>)
                                }
                                {
                                    props.ShowDownloadAction == "true" && props.ShowAction == "true" ?
                                        (<div className="col-md-4 float-right" >
                                            <button className="btn btn-primary float-right" style={{ whiteSpace: 'nowrap' }} onClick={() => props.AddDownloadAction()} color="success">{props.BtnIcon} {props.DownloadBtnTitle}</button>
                                        </div>) :
                                        (<></>)
                                }
                                {
                                    props.ShowDownloadAction == "true" && props.ShowAction == "true" ?
                                        (<div className="col-md-4" style={{ paddingRight: "2px" }}>
                                            <button className="btn btn-success float-right" style={{ whiteSpace: 'nowrap' }} onClick={() => props.AddNewAction()} color="success"><FaPlus /> New {props.ButtonTitle}</button>
                                        </div>) :
                                        (<></>)
                                }

                                {
                                    !props.ShowDownloadAction && props.ShowAction == "true" ?
                                        (<div className="col-md-7" style={{ paddingRight: "2px" }}>
                                            <button className="btn btn-success float-right" style={{ whiteSpace: 'nowrap' }} onClick={() => props.AddNewAction()} color="success"><FaPlus /> New {props.ButtonTitle}</button>
                                        </div>) :
                                        (<></>)
                                }

                            </div>
                        </div>
                        <div className="card-body">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContainerComponent
