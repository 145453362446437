import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Col, Form, Row, FormGroup, Label, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ContainerComponent from '../Container/ContainerComponent'
import { FaInfo, FaPlus } from 'react-icons/fa';

import toast, { Toaster } from 'react-hot-toast';
import { useParams } from "react-router-dom";
import * as CommonVariables from '../../Utility/CommonVariables';
import * as BucketMasterService from '../../Services/Bucket/BucketMasterService';
import { useHistory } from 'react-router-dom'
import ReactJson from 'react-json-view';
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';
import useLoaderState from '../../Utility/useLoaderState';

function BucketMasterDetailsComponent(props) {
    const { register, setValue, handleSubmit, reset, clearErrors, getValues, reasonData, formState: { errors } } = useForm({
        mode: "onBlur" // "onChange"
    });
    const [getBucketMasterList, setBucketMasterList] = useState([]);

    const { Master_Id } = useParams();
    const [error, setError] = useState("");
    const [IsLoading] = useLoaderState();
    const history = useHistory();

    useEffect(() => {
        if (Master_Id > 0) {
            GetAllMasterListById();
        }
    }, []);

    const BackToList = (e) => {
        history.push('/bucketMaster')
    }

    const GetAllMasterListById = () => {
        debugger;
        setError('');
        BucketMasterService.GetBucketMasterListById(Master_Id).then(data => {
            debugger;
            if (data.data.IsSuccess) {
                if (data.data.Data.length == 1) {
                    reset(data.data.Data[0]);
                }
                else {
                    reset(data.data.Data);
                }
            } else {
                setError(data.data.ResponseMessage);
                toast.error(data.data.ResponseMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const SaveMaster = (reasonData) => {
        setError('');
        BucketMasterService.SaveBucketMasterDetails(reasonData).then(data => {
            if (data.data.IsSuccess) {
                if (data.data.Data == 1) {
                    toast.success("Bucket Master " + "Saved Successfully");
                    BackToList();
                }
                else { toast.success("Record " + "Already Exist."); }
            } else {
                setError(data.data.ResponseMessage);
                toast.error(data.data.ResponseMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const UpdatMaster = (reasonData) => {
        setError('');
        debugger;
        BucketMasterService.UpdateBucketMasterDetails(reasonData).then(data => {
            if (data.data.IsSuccess) {
                if (data.data.Data == 1) {
                    toast.success("Bucket Master " + "Updated Successfully");
                    BackToList();
                }
                else { toast.success("Record " + "Already Exist."); }

            } else {
                setError(data.data.ResponseMessage);
                toast.error(data.data.ResponseMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }
    return (

        <ContainerComponent Title='Bucket Master' ShowAction="false">
            <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <FormGroup className="float-right">

            </FormGroup>
            <Form autoComplete="off">
                {error ? (<Alert color="danger">
                    {error}
                </Alert>) : <></>}
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />

                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label className="requiredfeild" >Bucket Name</Label>
                            <input type="text" placeholder="Bucket Name" className="form-control" {...register("bucket_name", { required: true, validate: (value) => { return !!value.trim() } })} ></input>
                            {errors.bucket_name && <p style={{ color: 'red' }}>Bucket Name is Required</p>}
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="requiredfeild" > Bucket Type</Label>
                            <input type="text" placeholder="Bucket Type" className="form-control" {...register("bucket_type", { required: true, validate: (value) => { return !!value.trim() } })} ></input>
                            {errors.bucket_type && <p style={{ color: 'red' }}>Bucket Type is Required</p>}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>

                    {
                        Master_Id > 0 ? <Col md="6">
                            <FormGroup>
                                <Label className="requiredfeild" > Is Active</Label> &nbsp;&nbsp;
                                <input type="checkbox"  {...register("Is_Active")} />
                            </FormGroup>
                        </Col> : ""} </Row>
                {Master_Id > 0 ?
                    <button type="submit" className="btn btn-primary" disabled={IsLoading} onClick={handleSubmit(UpdatMaster)}>Update</button> :
                    <button type="submit" className="btn btn-primary" disabled={IsLoading} onClick={handleSubmit(SaveMaster)}>Save</button>
                }
                {' '}<button type="button" className="btn btn-secondary" disabled={IsLoading} onClick={() => BackToList()}>Cancel</button>
            </Form>
        </ContainerComponent >
    );
}

export default BucketMasterDetailsComponent