import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Toaster, toast } from 'react-hot-toast';
import { connect } from 'react-redux';
import { Form, Button, FormGroup, Label, Alert } from 'reactstrap'
import * as LoginService from '../../Services/Login/LoginService';
import useLoaderState from '../../Utility/useLoaderState';
import { useHistory } from 'react-router-dom'
import { thunk_set_user } from '../../Action/action'
import Moment from 'react-moment';
import moment from 'moment';

function LoginComponent(props) {
    const { register, handleSubmit, formState: { errors }, watch, setValue, getValues } = useForm({ mode: "onChange" });
    const [error, setErrorMessage] = useState('');
    const [IsLoading] = useLoaderState();
    const [disable, setDisable] = useState(false);
    const history = useHistory();
    const [IsValidUser, setIsValidUser] = useState(false);
    const [OTPStartTime, setOTPExpiry] = useState(null);

    // const tagTypeuserName = watch("userName", "");
    // const tagTypepassword = watch("password", "");
    // const tagTypeuserEmail = watch("User_Email", "");
    const formSubmit = (value) => {
        setErrorMessage('');
        setDisable(true);
        setValue('userName', value.userName);
        setValue('password', value.password);
        debugger;
        const user = { UserName: value.userName, Password: value.password };
        let result = LoginService.ValidateUser(user).then(data => {
            debugger;
            if (data.data.IsSuccess) {
                setIsValidUser(true);
                setOTPExpiry(moment().add(3, 'minutes'));
            } else {
                setDisable(false);
                toast.error('Invalid User');
                setErrorMessage('Invalid User');
            }
        }).catch(error => {
            setDisable(false);
            setErrorMessage(error.message);
            toast.error(error.message);
        })
    }

    const reSendOTP = (value) => {
        setErrorMessage('');
        setDisable(true);
        value.userName = getValues('userName');
        value.password = getValues('password');
        formSubmit(value);
        setOTPExpiry(moment().add(3, 'minutes'));
        toast.success("OTP sent.");
    }

    const validateOTP = (value) => {
        setErrorMessage('');
        setDisable(true);
        const user = { UserName: value.userName, LoginOtp: value.LoginOtp };
        if (OTPStartTime < moment()) {
            toast.error('Sorry!! OTP Expired. Please resend and try again.');
            return;
        }

        let result = LoginService.ValidateOTP(user).then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setIsValidUser(true);
                props.dispatch(thunk_set_user(data.data));
                toast.success("Login Successful");
                setTimeout(() => {
                    history.push('/home');
                }, 1000);
            } else {
                setDisable(false);
                toast.error('Invalid OTP');
                setErrorMessage('Invalid OTP');
            }
        }).catch(error => {
            setDisable(false);
            setErrorMessage(error.message);
            toast.error(error.message);
        })
    }

    return (

        <div className="col-md-6 col-lg-7 d-flex align-items-center">
            {IsValidUser ?
                <div className="card-body p-4 p-lg-5 text-black">
                    <form>
                        <div className="d-flex align-items-center mb-3 pb-1">
                            <i className="fas fa-cubes fa-2x me-3"></i>
                            <span className="h1 fw-bold mb-0" style={{ color: "#00bc7a" }}>HiCare OTP</span>
                        </div>
                        <div className="form-outline mb-4">
                            <Toaster
                                position="top-center"
                                reverseOrder={false} />
                        </div>
                        <div className="form-outline mb-4">
                            <label className="form-label requiredfeild" > OTP</label>
                            <input type="number" id="loginOtp"  {...register("LoginOtp", { required: true, maxLength: 6, minLength: 6 })} className="form-control form-control-lg" />
                            {(errors.LoginOtp && errors.LoginOtp.type === "maxLength" && <p style={{ color: 'red' }}>Max length exceeded</p>) ||
                                (errors.LoginOtp && <p style={{ color: 'red' }}>Login OTP is Required</p>)}
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <button className="btn btn-success btn-sm btn-block" type="button" disabled={!IsValidUser} onClick={handleSubmit(validateOTP)}>Verify</button>
                            </div>
                            <div className="col-4">
                                <button className="btn btn-success btn-sm btn-block" type="button" disabled={!IsValidUser} onClick={(reSendOTP)}>Resend</button>
                            </div>
                        </div>
                    </form>
                </div>
                :

                <div className="card-body p-4 p-lg-5 text-black">
                    <form>
                        <div className="d-flex align-items-center mb-3 pb-1">
                            <i className="fas fa-cubes fa-2x me-3"></i>
                            <span className="h1 fw-bold mb-0" style={{ color: "#00bc7a" }}>HiCare Inventory</span>
                        </div>
                        <div className="form-outline mb-4">
                            <Toaster
                                position="top-center"
                                reverseOrder={false}
                            />
                        </div>
                        <div className="form-outline mb-4">
                            <label className="form-label requiredfeild" > Email address</label>
                            <input type="email" id="form2Example17" {...register("userName", { required: true })} className="form-control form-control-lg" />
                            {errors.userName && <p style={{ color: 'red' }}>Email is Required</p>}
                        </div>

                        <div className="form-outline mb-4">
                            <label className="form-label requiredfeild"> Password</label>
                            <input type="password" id="form2Example27" {...register("password", { required: true })} className="form-control form-control-lg" />
                            {errors.password && <p style={{ color: 'red' }}>Password is Required</p>}
                        </div>

                        <div className="pt-1 mb-4">
                            <button className="btn btn-success btn-lg btn-block" type="button" disabled={disable} onClick={handleSubmit(formSubmit)}>Login</button>
                        </div>
                    </form>
                </div>
            }
        </div>
    )
}

const matchStateToPros = state => {
    return {
        data: state
    }
}
export default connect(matchStateToPros)(LoginComponent);
