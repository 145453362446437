import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Col, Form, Row, FormGroup, Label, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ContainerComponent from '../Container/ContainerComponent'
import { FaInfo, FaPlus } from 'react-icons/fa';

import toast, { Toaster } from 'react-hot-toast';
import { useParams } from "react-router-dom";
import * as CommonVariables from '../../Utility/CommonVariables';
import * as BucketReasonService from '../../Services/Bucket/BucketReasonService';
import { useHistory } from 'react-router-dom'
import ReactJson from 'react-json-view';
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';
import useLoaderState from '../../Utility/useLoaderState';

function BucketReasonComponent(props) {
    const { register, setValue, handleSubmit, reset, clearErrors, getValues, reasonData, formState: { errors } } = useForm({
        mode: "onBlur" // "onChange"
    });
    const [getBucketMasterList, setBucketMasterList] = useState([]);
    const [ReasonList, setReasonList] = useState([]);
    const roleData = [{ Key: "Admin", Value: "Admin" }, { Key: "User", Value: "User" }]
    const { Reason_Id } = useParams();
    const [error, setError] = useState("");
    const [IsLoading] = useLoaderState();
    const history = useHistory();

    useEffect(() => {

        BucketMasterList();
        debugger;
        if (Reason_Id > 0) {
            GetAllReasonList();
        }
    }, []);

    const BackToList = (e) => {
        history.push('/bucketReason')
    }

    const BucketMasterList = () => {
        BucketReasonService.GetAllBucketMasterList().then(data => {
            if (data.data.IsSuccess) {
                setBucketMasterList(data.data.Data);
            } else {
                setBucketMasterList([]);
            }
        }).catch(error => {
            setBucketMasterList([]);
        })
    }
    const GetAllReasonList = () => {
        debugger;
        setError('');
        BucketReasonService.GetAllBucketReasonList(Reason_Id).then(data => {
            debugger;
            if (data.data.IsSuccess) {
                if (data.data.Data.length == 1) {
                    reset(data.data.Data[0]);
                }
                else {
                    reset(data.data.Data);
                }
            } else {
                setError(data.data.ResponseMessage);
                toast.error(data.data.ResponseMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const SaveReason = (reasonData) => {
        setError('');
        BucketReasonService.SaveReasonDetails(reasonData).then(data => {
            debugger;
            if (data.data.IsSuccess) {
                if (data.data.Data == 1) {
                    toast.success("Bucket Master " + "Saved Successfully");
                    BackToList();
                }
                else { toast.success("Record " + "Already Exist."); }

            } else {
                setError(data.data.ResponseMessage);
                toast.error(data.data.ResponseMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const UpdatReason = (reasonData) => {
        setError('');
        debugger;
        BucketReasonService.UpdateReasonDetails(reasonData).then(data => {
            if (data.data.IsSuccess) {
                if (data.data.Data == 1) {
                    toast.success("Bucket Master " + "Updated Successfully");
                    BackToList();
                }
                else { toast.success("Record " + "Already Exist."); }

            } else {
                setError(data.data.ResponseMessage);
                toast.error(data.data.ResponseMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const allowonlynumber = (e) => {
        var a = [];
        var k = e.which;

        for (var i = 48; i < 58; i++)
            a.push(i);

        if (!(a.indexOf(k) >= 0))
            e.preventDefault();
    };

    return (

        <ContainerComponent Title='Bucket Reason Master' ShowAction="false">
            <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <FormGroup className="float-right">

            </FormGroup>
            <Form autoComplete="off">
                {error ? (<Alert color="danger">
                    {error}
                </Alert>) : <></>}
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />

                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label className="requiredfeild" >Bucket Name</Label>
                            <select className="form-control" {...register("Bucket_Id", { required: "Mandatory field" })}>
                                <option value="">Select Bucket</option>
                                {
                                    getBucketMasterList != null && getBucketMasterList.map((data, index) => {
                                        return (
                                            <option value={data.Id} key={index}>{data.bucket_name}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.Bucket_Id && <p style={{ color: 'red' }}>Bucket is Required</p>}
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="requiredfeild" > Reason</Label>
                            <input type="text" placeholder="Reason" className="form-control" {...register("Reasons", { required: true, validate: (value) => { return !!value.trim() } })} ></input>
                            {errors.Reasons && <p style={{ color: 'red' }}>Reason is Required</p>}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label className="requiredfeild" >Allocate Bucket Name</Label>
                            <select className="form-control" {...register("Allocate_Bucket_Id", { required: "Mandatory field" })}>
                                <option value="">Select Allocate Bucket</option>
                                {
                                    getBucketMasterList != null && getBucketMasterList.map((data, index) => {
                                        return (
                                            <option value={data.Id} key={index}>{data.bucket_type}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.Allocate_Bucket_Id && <p style={{ color: 'red' }}>Allocate Bucket is Required</p>}
                        </FormGroup>
                    </Col>
                    <Col md="3">
                            <FormGroup>
                                <Label  > ShowText </Label> &nbsp;&nbsp;
                                <input type="checkbox"  {...register("ShowText")} />
                            </FormGroup>
                        </Col>
                    {
                        Reason_Id > 0 ? <Col md="3">
                            <FormGroup>
                                <Label className="requiredfeild" > Is Active</Label> &nbsp;&nbsp;
                                <input type="checkbox"  {...register("Is_Active")} />
                            </FormGroup>
                        </Col> : ""} </Row>
                {Reason_Id > 0 ?
                    <button type="submit" className="btn btn-primary" disabled={IsLoading} onClick={handleSubmit(UpdatReason)}>Update</button> :
                    <button type="submit" className="btn btn-primary" disabled={IsLoading} onClick={handleSubmit(SaveReason)}>Save</button>
                }
                {' '}<button type="button" className="btn btn-secondary" disabled={IsLoading} onClick={() => BackToList()}>Cancel</button>
            </Form>
        </ContainerComponent >
    );
}

export default BucketReasonComponent