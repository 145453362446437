import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { Button, Table, Alert, Row, Col, InputGroup } from 'reactstrap';
import { FaPlus, FaCheckCircle, FaSearch, FaHistory, FaTimesCircle, FaEdit, FaRegTrashAlt, FaBitbucket } from 'react-icons/fa';
import * as BucketReasonService from '../../Services/Bucket/BucketReasonService';
import ContainerComponent from '../Container/ContainerComponent';
import { useHistory } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast';
import { useParams } from "react-router-dom";
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';
import ReactPagniate from "react-paginate";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

function ReasonListComponent() {
    const { register, setValue, handleSubmit, watch, clearErrors, getValues, reasonData, formState: { errors } } = useForm({
        mode: "onBlur" // "onChange"
    });
    const [isModelOpen, setModel] = useState(true);
    const [ReasonList, setReasonList] = useState([]);
    const [selectedUserId, setUserId] = useState(0);
    const [error, setError] = useState("");
    const [editMode, SetEditMode] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const history = useHistory();
    const [pageNumber, setPageNumber] = useState(0);
    const SearchText = watch("Search_Text", "");
    const PER_PAGE = 20;
    const offset = currentPage * PER_PAGE;
    const currentPageData = ReasonList
        .slice(offset, offset + PER_PAGE)
        .map(({ thumburl }) => <img src={thumburl} />);
    const pageCount = Math.ceil(ReasonList.length / PER_PAGE);
    // const { sorteType, setSorteType } = useState([]);
    // const sorted = ReasonList.sort((a, b) => {
    //     const isReversed = (setSorteType === 'asc') ? 1 : -1;
    //     return isReversed * a.bucket_name.localeCompare(b.bucket_name);
    // })
    const displayUsers = ReasonList.slice(offset, offset + PER_PAGE).map((Reason, Index) => {
        return (
            <tr key={Index}>
                <td>{Reason.Id}</td>
                <td>{Reason.bucket_name} </td>
                <td>{Reason.bucket_type}</td>
                <td>{Reason.Reasons}</td>
                <td>
                    {
                        Reason.ShowText ? <FaCheckCircle style={{ color: 'green' }} /> : <FaTimesCircle style={{ color: 'red' }} />
                    }
                </td>
                <td>
                    {
                        Reason.Is_Active ? <FaCheckCircle style={{ color: 'green' }} /> : <FaTimesCircle style={{ color: 'red' }} />
                    }
                </td>
                <td>
                    <Tooltip title="Edit">
                        <IconButton style={{ height: '10px', width: '45px' }}>
                            <FaEdit style={{ cursor: "pointer" }} onClick={
                                () => { editNewBucketReason(Reason.Id) }
                            } /></IconButton></Tooltip>
                    {
                        Reason.Is_Active ?
                            <Tooltip title="Active">
                                <IconButton style={{ height: '10px', width: '45px' }}>
                                    <FaRegTrashAlt style={{ color: 'green', cursor: "pointer" }} onClick={() => { deleteReason(Reason.Id, Reason.Is_Active) }} />
                                </IconButton></Tooltip>
                            : <Tooltip title="DeActive">
                            <IconButton style={{ height: '10px', width: '45px' }}><FaRegTrashAlt style={{ color: 'red', cursor: "pointer" }} onClick={() => { deleteReason(Reason.Id, Reason.Is_Active) }} /></IconButton></Tooltip>
                    }
                </td>
            </tr>
        );

    });

    const { Reason_Id } = useParams();
    useEffect(() => {
        GetAllReasonList();
    }, [])

    const GetAllReasonList = () => {
        debugger;
        setError('');
        BucketReasonService.GetAllBucketReasonList(Reason_Id).then(data => {
            debugger;
            if (data.data.IsSuccess) {
                //console.log(data.data.Data)
                setReasonList(data.data.Data);

            } else {
                setError(data.data.ResponseMessage);
                toast.error(data.data.ResponseMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }
    function handleChange({ event: selectedEvent }) {
        this.setState({ filter: event.target.value });
    };

    const SearchReason = () => {
        debugger;

        setError('');
        BucketReasonService.SearchReason(SearchText).then(data => {
            if (SearchText != undefined && SearchText.length >= 2) {
                debugger;
                if (data.data.IsSuccess) {
                    //console.log(data.data.Data)
                    setReasonList(data.data.Data);

                } else {
                    setReasonList([]);
                    toast.error('Record Not Found');
                    // toast.error(error.message);

                }
            }
            else {
                if (SearchText.length <= 0) {
                    GetAllReasonList();
                }
                else {
                    setError('Record Not Found');
                    // toast.error('Text required more than 3 char.');
                    // GetAllReasonList();
                }
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }
    function handleChange({ event: selectedEvent }) {
        this.setState({ filter: event.target.value });
    };

    function render() {
        const { filter, data } = this.state;
        const lowercasedFilter = filter.toLowerCase();
        const filteredData = data.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toLowerCase().includes(lowercasedFilter)
            );
        })
    }

    function handlePageClick({ selected: selectedPage }) {
        debugger;
        setCurrentPage(selectedPage);
    }
    const deleteReason = (Reason_Id, Is_Active) => {
        debugger;
        setError('');
        var action = Is_Active ? window.confirm('Do you want to deactivate the Bucket reason?') : window.confirm('Do you want to activate the Bucket reason?');
        if (action == true) {
            BucketReasonService.ActivateDeactiveReason(Reason_Id, !Is_Active).then(data => {
                if (data.data.IsSuccess) {
                    if (Is_Active) {
                        toast.success("Deactivated Successfully");
                    }
                    else {
                        toast.success("Activated Successfully");
                    }
                    GetAllReasonList();
                } else {
                    setError(data.data.ResponseMessage);
                    toast.error(data.data.ResponseMessage);
                }
            }).catch(error => {
                if (error.message.includes("401")) {
                    toast.error("Your session is expired, Redirecting to Login page")
                    history.push('')
                }
                else {
                    setError(error.message);
                    toast.error(error.message);
                }
            });
        }
    }

    const addNewBucketReason = () => {
        history.push('/BucketReason/add/' + 0);
    }
    const editNewBucketReason = (Bucket_Id) => {
        debugger;
        history.push('/BucketReason/edit/' + parseInt(Bucket_Id));
    }

    return (
        <ContainerComponent Title="Bucket Reason Master" ButtonTitle="Reason" Icon={<FaHistory />} AddNewAction={addNewBucketReason} ShowAction="true">

            {error ? (<Alert color="danger">
                {error}
            </Alert>) : <></>}
            <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            {/* 
            <div className="row">
                <div className="col-8"></div>
                <div className="col-4">
                    <div className="input-group-text">
                        <input placeholder="Search..." {...register("Search_Text")} className="form-control" />
                        <div className="input-group-append">
                            <button className="btn btn-outline-success" type="button" onClick={handleSubmit(SearchReason)}>
                                {<FaSearch />}
                            </button>
                        </div>
                    </div></div>
            </div>
            <br></br> */}
            <Table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Bucket Name</th>
                        <th>Allocate New Bucket</th>
                        <th>Reasons</th>
                        <th>Show Text</th>
                        <th>Active</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>{displayUsers}
                    {displayUsers.length <= 0
                        ?
                        <tr>
                            <td colSpan="7">No Data Available</td>
                        </tr>
                        :
                        <></>}
                    {/* {displayUsers} */}
                    {/* {
                        displayUsers.length <= 0
                            ?
                            <tr>
                                <td colSpan="7">No Data Available</td>
                            </tr>
                            :
                            <></>
                    } */}
                </tbody>
            </Table>
            <ReactPagniate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                previousLinkClassName={"page-item"}
                nextLinkClassName={"page-item"}
                disabledClassName={"page-item disabled"}
                activeClassName={"page-item active"}
            />

        </ContainerComponent>
    )

}
export default ReasonListComponent